import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';

function adjust(color, amount) {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}

const corPadraoFideleco = '#FD5958';

const ThemeContainer = ({ children }) => {
  const { plataformaData } = useSelector((state) => state.plataformaLoja);
  const plataformaCor = plataformaData?.plataforma?.cor;
  const theme = {
    mainColor:
      plataformaCor && plataformaCor !== '' ? plataformaCor : corPadraoFideleco,
    mainColorFaded:
      plataformaCor && plataformaCor !== ''
        ? `${plataformaCor}99`
        : `${corPadraoFideleco}99`,
    lighterColor:
      plataformaCor && plataformaCor !== ''
        ? adjust(plataformaCor, 99)
        : adjust(corPadraoFideleco, 99),
    lighterColorFaded:
      plataformaCor && plataformaCor !== ''
        ? `${adjust(plataformaCor, 99)}99`
        : `${adjust(corPadraoFideleco, 99)}99`,
    darkerColor:
      plataformaCor && plataformaCor !== ''
        ? adjust(plataformaCor, -99)
        : adjust(corPadraoFideleco, -99),
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContainer;
