import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import Router from './routes';

import { Spinner } from 'reactstrap';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './assets/styles/index.scss';

// components
import Modal from './components/Modal';
import { Vincular, Desvincular } from './components/Vincular';

// import FreshChat from './api/freshChat';
import { Container } from './styles/shared/Container';
import AceiteTermos from './components/AceiteTermos';

const Login = React.lazy(() => import('./components/Login'));
const RecuperaSenha = React.lazy(() => import('./components/RecuperaSenha'));
const ValidarCupom = React.lazy(() => import('./components/ValidarCupom'));
const RegisterFluxo1 = React.lazy(() =>
  import('./components/Register/RegistroFluxo1')
);
const RegisterFluxo2 = React.lazy(() =>
  import('./components/Register/RegistroFluxo2')
);

const FreshChat = React.lazy(() => import('./api/freshChat'));

function App() {
  const { componente } = useSelector((state) => state.modal);
  const { plataformaPathname } = useSelector((state) => state.plataformaLoja);

  return (
    <>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={3500}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <Modal>
        <Suspense
          fallback={
            <Container height="400px" backgroundColor="#fff">
              <Spinner
                style={{ width: '10rem', height: '10rem' }}
                color="danger"
              />
            </Container>
          }
        >
          {componente === 'login' ? (
            <Login />
          ) : componente === 'register1' ? (
            <RegisterFluxo1 />
          ) : componente === 'register2' ? (
            <RegisterFluxo2 />
          ) : componente === 'validarCupom' ? (
            <ValidarCupom />
          ) : componente === 'vincularCliente' ? (
            <Vincular />
          ) : componente === 'desvincularCliente' ? (
            <Desvincular />
          ) : componente === 'aceiteTermos' ? (
            <AceiteTermos />
          ) : (
            <RecuperaSenha />
          )}
        </Suspense>
      </Modal>
      <Router />
      {plataformaPathname && (
        <Suspense fallback={null}>
          <FreshChat />
        </Suspense>
      )}
    </>
  );
}

export default App;
