import api from '../../api/api';
import { toast } from 'react-toastify';

// actions
import { abrirModal } from '../../store/actions/modalActions';

export const googleLogin = (codigo) => async (dispatch) => {
  const { plataforma, loja } = JSON.parse(
    localStorage.getItem('@Plataforma:Loja')
  );

  dispatch({
    type: 'USUARIO_LOGIN_GOOGLE_REQUEST',
  });

  api
    .post(`/hotsite/google/login`, {
      codigoGoogle: codigo,
      caminhoPlataforma: plataforma,
      caminhoLoja: loja,
    })
    .then((response) => {
      if (response.data.cliente.clienteId === 0) {
        dispatch({
          type: 'USUARIO_LOGIN_GOOGLE_FAIL',
        });

        toast.warn(
          'Nenhum usuário cadastrado no email informado. Realise o seu cadastro.'
        );

        setTimeout(() => {
          window.location = `/${plataforma}/${loja}/home?cadastro`;
        }, 1000);
      } else {
        dispatch({
          type: 'USUARIO_LOGIN_GOOGLE_SUCCESS',
          payload: response.data,
        });

        toast.success('Seja bem-vindo!');

        localStorage.setItem(
          `Hotsite:Usuario`,
          JSON.stringify({ cliente: response.data.cliente })
        );

        localStorage.setItem(
          `Hotsite:Token`,
          JSON.stringify(response.data.token)
        );

        setTimeout(() => {
          window.location = `/${plataforma}/${loja}/`;
        }, 1500);
      }
    })
    .catch((error) => {
      toast.warning(error.response.data.error[0].message);
      dispatch({
        type: 'USUARIO_LOGIN_GOOGLE_FAIL',
      });
    });
};

export const facebookLogin = (codigo) => async (dispatch) => {
  const { plataforma, loja } = JSON.parse(
    localStorage.getItem('@Plataforma:Loja')
  );

  dispatch({
    type: 'USUARIO_LOGIN_FACEBOOK_REQUEST',
  });

  api
    .post(`/hotsite/facebook/login`, {
      codigoFacebook: codigo,
      caminhoPlataforma: plataforma,
      caminhoLoja: loja,
    })
    .then((response) => {
      if (response.data.cliente.clienteId === 0) {
        dispatch({
          type: 'USUARIO_LOGIN_FACEBOOK_FAIL',
        });

        toast.warn(
          'Nenhum usuário cadastrado no email informado. Realise o seu cadastro.'
        );

        setTimeout(() => {
          window.location = `/${plataforma}/${loja}/home?cadastro`;
        }, 1000);
      } else {
        dispatch({
          type: 'USUARIO_LOGIN_FACEBOOK_SUCCESS',
          payload: response.data,
        });

        toast.success(`Seja bem-vindo, ${response.data.cliente.nome}!`);

        localStorage.setItem(
          `Hotsite:Usuario`,
          JSON.stringify({ cliente: response.data.cliente })
        );

        localStorage.setItem(
          `Hotsite:Token`,
          JSON.stringify(response.data.token)
        );

        setTimeout(() => {
          window.location = `/${plataforma}/${loja}/`;
        }, 1500);
      }
    })
    .catch((error) => {
      toast.warning(error.response.data.error[0].message);
      dispatch({
        type: 'USUARIO_LOGIN_FACEBOOK_FAIL',
      });
    });
};
