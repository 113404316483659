import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// reducers
import { usuarioLoginReducer } from './reducers/usuarioReducers';
import { modalReducer } from './reducers/modalReducer';
import { plataformaLojaReducer } from './reducers/plataformaLojaReducer';
import { premioReducer } from './reducers/premioReducer';
import { vouchersReducer } from './reducers/vouchersReducer';
import { alterarTelaDados } from './reducers/alterarTelaDados';

const rootReducer = combineReducers({
  usuarioLogin: usuarioLoginReducer,
  modal: modalReducer,
  plataformaLoja: plataformaLojaReducer,
  premio: premioReducer,
  vouchers: vouchersReducer,
  alterarTelaDados,
});

// checa se existem dados no localstorage
const userFromStorage = localStorage.getItem(`Hotsite:Usuario`)
  ? JSON.parse(localStorage.getItem(`Hotsite:Usuario`))
  : null;

const tokenFromStorage = localStorage.getItem(`Hotsite:Token`)
  ? JSON.parse(localStorage.getItem(`Hotsite:Token`))
  : '';

const initialState = {
  usuarioLogin: {
    usuario: userFromStorage,
    loading: false,
    token: tokenFromStorage,
  },
  modal: { open: false, componente: 'login' },
  plataformaLoja: {
    plataformaPathname: '',
    lojaPathname: '',
    plataformaData: {},
    loading: true,
  },
  premio: { premio: {}, loading: false },
  vouchers: {
    gerados: { totalItems: 0, data: [] },
    resgatados: { totalItems: 0, data: [] },
  },

  alterarTelaDados: { tela: 'dados' },
};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
