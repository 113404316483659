import React, { useCallback, useState, useEffect, useRef } from 'react';

//Libis
import { useField } from '@unform/core';

import { Container } from './styles';

// icons
import EyeOpen from '../../assets/icons/eye-open-red.svg';
import EyeClose from '../../assets/icons/eye-close-red.svg';

import Tooltip from '../../components/tooltip';
import { removeAllSpecialChars, getMaskedValue } from '../../helpers/functions';

const Input = ({
  name,
  label,
  type,
  mask,
  estilo,
  initialValue,
  mostraSenha,
  setMostraSenha,
  iconSenha,
  isPassword,
  autoComplete = 'on',
  tooltipMessage,
  ...rest
}) => {
  //states
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(initialValue || '');

  //Variaveis
  const inputRef = useRef(null);
  const { fieldName, error, registerField } = useField(name);

  //Fuctions
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const onChange = (ev) => {
    const { value } = ev.target;
    if (mask) {
      return setValue(getMaskedValue(value, mask));
    }

    if (type === 'email' || isPassword) {
      return setValue(value);
    }
    setValue(removeAllSpecialChars(value));
  };

  return (
    <Container isFocused={isFocused} style={estilo}>
      <div className="label">
        <label className="input-title">{label}</label>
        {tooltipMessage && <Tooltip texto={tooltipMessage} id={name} />}
      </div>
      <input
        name={name}
        ref={inputRef}
        onChange={onChange}
        value={value}
        className="input-contato"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        type={type}
        autoComplete={autoComplete}
        {...rest}
      />
      {iconSenha && (
        <div className="icon-senha">
          <img
            src={mostraSenha ? EyeOpen : EyeClose}
            onClick={() => setMostraSenha(!mostraSenha)}
            alt="Mostrar senha"
          />
        </div>
      )}
      {error && <span className="erro">{error}</span>}
    </Container>
  );
};

export default Input;
