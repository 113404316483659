import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-contato {
    width: 100% !important;

    @media (max-width: 414px) {
      width: 70% !important;
    }
  }

  h4 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 3rem;
  }

  p {
    width: 28rem;
    max-width: 100%;
    font-size: 1.4rem !important;
    text-align: center;
    margin: 0 auto 4rem;
    line-height: 1.6rem;
  }

  span {
    margin-top: 4rem;
    line-height: 0rem;
  }
`;
