import { mask as masker, unMask } from 'remask';

export const formataData = (data, formato) => {
  if (data === null) {
    return data;
  }
  if (formato === 'DD/MM/AAAA') {
    const ano = data?.split('-')?.[0];
    const mes = data?.split('-')?.[1];
    const dia = data?.split('-')?.[2];

    return [dia, mes, ano].join('/');
  } else if (formato === 'AAAA-MM-DD') {
    const dia = data?.split('/')?.[0];
    const mes = data?.split('/')?.[1];
    const ano = data?.split('/')?.[2];

    return [ano, mes, dia].join('-');
  }
};

export function removeAllSpecialChars(string) {
  const cleanString = string.replace(/[^a-zA-Z0-9 ]/g, '');
  return cleanString;
}

export const getMaskedValue = (value, mask) => {
  const originalValue = unMask(value);

  if (mask === 'celular') {
    const maskedValue = masker(originalValue, [
      '(99) 9999-9999',
      '(99) 9 9999-9999',
    ]);
    return maskedValue;
  }
  if (mask === 'pessoa') {
    const maskedValue = masker(originalValue, [
      '999.999.999-99',
      '99.999.999/9999-99',
    ]);

    return maskedValue;
  }
  if (mask === 'cep') {
    const maskedValue = masker(originalValue, ['99999-999']);

    return maskedValue;
  }
  if (mask === 'data') {
    const maskedValue = masker(originalValue, ['99/99/9999']);

    return maskedValue;
  }

  // data de abertura de empresa em formato MM/YYYY
  if (mask === 'data-cnpj') {
    const maskedValue = masker(originalValue, ['99/9999']);

    return maskedValue;
  }
  return value;
};
