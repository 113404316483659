import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-contato {
    width: 100% !important;

    @media (max-width: 414px) {
      width: 70% !important;
    }
  }

  h4 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 3rem;
  }

  p {
    width: 28rem;
    max-width: 100%;
    font-size: 1.4rem !important;
    text-align: center;
    margin: 0 auto 4rem;
    line-height: 1.6rem;
  }

  span {
    margin-top: 4rem;
    line-height: 0rem;
  }

  .info {
    margin-bottom: 25px;
    > span.show-modal {
      color: #083cff;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const CloseModalTermosPoliticas = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  font-weight: bold;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2rem;
    line-height: 2rem;
    background: coral;
    width: 2.4rem;
    height: 2.4rem;
    padding: 1.4rem;
    border-radius: 50%;

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
