import styled from 'styled-components';

export const Button = styled.button`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.mainColor};
  padding: ${(props) => (props.padding ? props.padding : '2rem 4rem')};
  margin: ${(props) => (props.margin ? props.margin : '2rem 0')};
  color: ${(props) => (props.textColor ? props.textColor : '#FFFFFF')};
  width: ${(props) => (props.width ? props.width : '100%')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  border: none;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4rem'};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : '')};
  justify-self: ${(props) => (props.justifySelf ? props.justifySelf : '')};
  font-size: ${(props) => props.fontSize || '2rem'};
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  text-align: center;

  &:hover {
    background: ${(props) =>
      props.backgroundColorHover ? props.backgroundColorHover : ''};
    color: ${(props) => (props.textColorHover ? props.textColorHover : '')};
    filter: saturate(200%);
  }
`;
