import api from '../../api/api';
import axios from 'axios';
import { toast } from 'react-toastify';

import { abrirModal, fecharModal } from './modalActions';

// action types
import {
  USUARIO_LOGIN_REQUEST,
  USUARIO_LOGIN_SUCCESS,
  USUARIO_LOGIN_FAIL,
  USUARIO_LOGOUT,
  USUARIO_ALTERA_SENHA_REQUEST,
  USUARIO_ALTERA_SENHA_SUCCESS,
  USUARIO_ALTERA_SENHA_FAIL,
  USUARIO_REGISTER_REQUEST,
  USUARIO_REGISTER_SUCCESS,
  USUARIO_REGISTER_FAIL,
  USUARIO_COMPLETA_REQUEST,
  USUARIO_COMPLETA_SUCCESS,
  USUARIO_COMPLETA_FAIL,
  USUARIO_RELOAD_REQUEST,
  USUARIO_RELOAD_SUCCESS,
  USUARIO_RELOAD_FAIL,
  USUARIO_VINCULADO_AO_COMPLETAR_CADASTRO,
} from '../types/usuario';

/////////// LOGIN/LOGOUT

export const login = (cpfCnpj, senha) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USUARIO_LOGIN_REQUEST,
    });

    const { plataformaPathname, lojaPathname } = getState().plataformaLoja;

    const { data } = await api.post('/hotsite/cliente/login', {
      cpfCnpj,
      senha,
      caminhoPlataforma: plataformaPathname,
      caminhoLoja: lojaPathname,
    });

    dispatch({
      type: USUARIO_LOGIN_SUCCESS,
      payload: data,
    });

    toast.success(`Seja bem-vindo, ${data.cliente.nome}`);

    localStorage.setItem(
      `Hotsite:Usuario`,
      JSON.stringify({ cliente: data.cliente })
    );

    localStorage.setItem(`Hotsite:Token`, JSON.stringify(data.token));

    dispatch(fecharModal);

    if (
      data.cliente.cadastroCompleto &&
      data.cliente.clientePossuiVinculoLoja !== true
    ) {
      dispatch(abrirModal('vincularCliente'));
    } else {
      //recarregar a pagina para que o freshchat pegue os dados atualizado para o chat
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  } catch (error) {
    toast.warning(error.response.data.error[0].message);
    dispatch({
      type: USUARIO_LOGIN_FAIL,
      payload: error.response.data.error[0].message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem(`Hotsite:Usuario`);
  localStorage.removeItem(`Hotsite:Token`);
  localStorage.removeItem(`@Plataforma:Loja`);

  //recarregar a pagina para que o freshchat pegue os dados atualizado para o chat
  window.location.reload();

  dispatch({
    type: USUARIO_LOGOUT,
  });
};

////////////// RELOAD (login) do Usuário
// Chamada quando o usuário altera seus dados na tela /meus-dados
// Realiza login pra trazer as informações atualizadas e salvar no state
export const reloadUsuario = () => async (dispatch, getState) => {
  const { plataformaPathname, lojaPathname } = getState().plataformaLoja;

  try {
    dispatch({
      type: USUARIO_RELOAD_REQUEST,
    });

    const { data } = await api.get('/hotsite/cliente', {
      params: {
        caminhoPlataforma: plataformaPathname,
        caminhoLoja: lojaPathname,
      },
    });

    dispatch({
      type: USUARIO_RELOAD_SUCCESS,
      payload: data,
    });

    localStorage.setItem(`Hotsite:Usuario`, JSON.stringify({ cliente: data }));
  } catch (error) {
    toast.warning(error.response.data.error[0].message);
    dispatch({
      type: USUARIO_RELOAD_FAIL,
      payload: error.response.data.error[0].message,
    });
  }
};

///////////// CADASTRO

export const cadastrarUsuario = (info) => async (dispatch) => {
  const { nome, cpfCnpj, email, celular, senha, aceiteTermo } = info;
  try {
    dispatch({
      type: USUARIO_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await api.post(
      '/hotsite/cliente/simplificado',
      { nome, cpfCnpj, email, celular, senha, aceiteTermo: aceiteTermo },
      config
    );

    dispatch({
      type: USUARIO_REGISTER_SUCCESS,
      payload: data,
    });

    toast.info('Próximo passo.');

    await dispatch(login(cpfCnpj, senha));

    dispatch(abrirModal('register2'));
  } catch (error) {
    toast.warning(error.response.data.error[0].message);
    dispatch({
      type: USUARIO_REGISTER_FAIL,
      payload: error.response.data.error[0].message,
    });
  }
};

export const completarCadastro = (info) => async (dispatch, getState) => {
  const {
    generoId,
    lojaId,
    dataNascimento,
    cep,
    endereco,
    numero,
    bairro,
    complemento,
    cidade,
    uf,
  } = info;
  const {
    usuarioLogin: {
      usuario: {
        cliente: { clienteId },
      },
    },
  } = getState();
  try {
    dispatch({
      type: USUARIO_COMPLETA_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await api.put(
      '/hotsite/cliente/completarCadastro',
      {
        clienteId,
        generoId,
        lojaId,
        dataNascimento,
        cep,
        endereco,
        numero,
        bairro,
        complemento,
        cidade,
        uf,
      },
      config
    );

    dispatch({
      type: USUARIO_COMPLETA_SUCCESS,
    });

    dispatch({
      type: USUARIO_VINCULADO_AO_COMPLETAR_CADASTRO,
    });

    const { usuarioLogin } = getState();

    localStorage.setItem(
      'Hotsite:Usuario',
      JSON.stringify(usuarioLogin['usuario'])
    );

    dispatch(reloadUsuario());

    toast.info(data.message);

    dispatch(fecharModal);
  } catch (error) {
    toast.warning(error.response.data.error[0].message);
    dispatch({
      type: USUARIO_COMPLETA_FAIL,
      payload: error.response.data.error[0].message,
    });
  }
};

//////////////////////// ALTERAR SENHA

export const alterarSenha =
  (senhaAntiga, senhaNova, confirmarSenha) => async (dispatch, getState) => {
    const {
      usuarioLogin: {
        usuario: {
          cliente: { clienteId },
        },
      },
    } = getState();

    try {
      dispatch({
        type: USUARIO_ALTERA_SENHA_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await api.put(
        '/hotsite/cliente/alterarsenha',
        {
          clienteId,
          senhaAntiga,
          senhaNova,
          confirmarSenha,
        },
        config
      );

      dispatch({
        type: USUARIO_ALTERA_SENHA_SUCCESS,
        payload: data,
      });

      toast.success('Senha alterada com sucesso!');
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    } catch (error) {
      toast.warning(error.response.data.error[0].message);
      dispatch({
        type: USUARIO_ALTERA_SENHA_FAIL,
        payload: error.response.data.error[0].message,
      });
    }
  };

////////////// VINCULAÇÃO
export const vincularCliente = (aceiteTermo) => async (dispatch, getState) => {
  const { plataformaPathname, lojaPathname } = getState().plataformaLoja;
  try {
    dispatch({
      type: 'USUARIO_VINCULA_REQUEST',
    });

    const { data } = await api.put('/hotsite/clienteloja/vincular', {
      caminhoPlataforma: plataformaPathname,
      caminhoLoja: lojaPathname,
      aceiteTermo,
    });

    dispatch({
      type: 'USUARIO_VINCULA_SUCCESS',
    });

    // chama função reload para persistir as alterações de dados
    try {
      dispatch({
        type: USUARIO_RELOAD_REQUEST,
      });

      const { data } = await api.get('/hotsite/cliente', {
        params: {
          caminhoPlataforma: plataformaPathname,
          caminhoLoja: lojaPathname,
        },
      });

      dispatch({
        type: USUARIO_RELOAD_SUCCESS,
        payload: data,
      });

      localStorage.setItem(
        `Hotsite:Usuario`,
        JSON.stringify({ cliente: data })
      );

      dispatch({
        type: 'FECHA_MODAL',
      });
    } catch (error) {
      toast.warning(error.response.data.error[0].message);
      dispatch({
        type: USUARIO_RELOAD_FAIL,
        payload: error.response.data.error[0].message,
      });
    }

    toast.success(
      'Parabéns! Você já é cliente Fideleco e agora está vinculado à essa loja.'
    );

    //recarregar a pagina para que o freshchat pegue os dados atualizado para o chat
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    toast.error(error.response.data.error[0].message);
    if (error.response.status === 400) {
      dispatch({
        type: 'FECHA_MODAL',
      });
    }
    dispatch({
      type: 'USUARIO_VINCULA_FAIL',
      payload: error.response.data.error[0].message,
    });
  }
};

export const desvincularCliente =
  (senhaCliente) => async (dispatch, getState) => {
    try {
      dispatch({
        type: 'USUARIO_DESVINCULA_REQUEST',
      });

      const { data } = await api.put('/hotsite/clienteloja/desvincular', {
        senhaCliente,
      });

      dispatch({
        type: 'USUARIO_DESVINCULA_SUCCESS',
      });

      // chama função reload para persistir as alterações de dados
      try {
        dispatch({
          type: USUARIO_RELOAD_REQUEST,
        });

        const { data } = await api.get('/hotsite/cliente');

        dispatch({
          type: USUARIO_RELOAD_SUCCESS,
          payload: data,
        });

        localStorage.setItem(
          `Hotsite:Usuario`,
          JSON.stringify({ cliente: data })
        );

        dispatch({
          type: 'FECHA_MODAL',
        });
      } catch (error) {
        toast.warning(error.response.data.error[0].message);
        dispatch({
          type: USUARIO_RELOAD_FAIL,
          payload: error.response.data.error[0].message,
        });
      }

      toast.success('Você foi desvinculado dessa loja.');
    } catch (error) {
      toast.error(error.response.data.error[0].message);

      dispatch({
        type: 'USUARIO_DESVINCULA_FAIL',
        payload: error.response.data.error[0].message,
      });
    }
  };
