import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;

  > img.tooltip-icon {
    margin-left: 0.5rem;
  }

  > p.tooltip-message {
    position: absolute;
    left: -30px;
    display: none;
    width: 20rem;
    max-height: 300px;
    height: auto;
    background: ${(props) => props.theme.mainColor};
    color: #fff;
    font-weight: bold;
    text-align: left;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    z-index: 99 !important;
  }

  > p.tooltip-message.showTooltipMessage {
    display: flex;
  }

  img.tooltip-icon:hover + p.tooltip-message {
    display: flex;
  }
`;
