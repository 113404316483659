// action types
import {
  USUARIO_LOGIN_REQUEST,
  USUARIO_LOGIN_SUCCESS,
  USUARIO_LOGIN_FAIL,
  USUARIO_LOGOUT,
  USUARIO_LOGIN_GOOGLE_REQUEST,
  USUARIO_LOGIN_GOOGLE_SUCCESS,
  USUARIO_LOGIN_GOOGLE_FAIL,
  USUARIO_LOGIN_FACEBOOK_REQUEST,
  USUARIO_LOGIN_FACEBOOK_SUCCESS,
  USUARIO_LOGIN_FACEBOOK_FAIL,
  USUARIO_REGISTER_REQUEST,
  USUARIO_REGISTER_SUCCESS,
  USUARIO_REGISTER_FAIL,
  USUARIO_COMPLETA_SUCCESS,
  USUARIO_RELOAD_REQUEST,
  USUARIO_RELOAD_SUCCESS,
  USUARIO_RELOAD_FAIL,
  USUARIO_VINCULADO_AO_COMPLETAR_CADASTRO,
  USUARIO_ACEITE_TERMOS,
} from '../types/usuario';

export const usuarioLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USUARIO_LOGIN_REQUEST:
      return { ...state, loading: true };
    case USUARIO_LOGIN_SUCCESS:
      return { loading: false, usuario: action.payload };
    case USUARIO_LOGIN_FAIL:
      return { loading: false, error: action.payload, usuario: null };
    case USUARIO_LOGOUT:
      return { loading: false, usuario: null };
    case USUARIO_REGISTER_REQUEST:
      return { loading: true, usuario: null };
    case USUARIO_REGISTER_SUCCESS:
      return { loading: false, ...state };
    case USUARIO_REGISTER_FAIL:
      return { loading: false, usuario: null };
    case USUARIO_COMPLETA_SUCCESS:
      return {
        usuario: {
          cliente: { ...state.usuario.cliente, cadastroCompleto: true },
        },
      };
    case USUARIO_LOGIN_GOOGLE_REQUEST:
      return {
        loading: true,
        usuario: null,
      };
    case USUARIO_LOGIN_GOOGLE_SUCCESS:
      return {
        loading: false,
        usuario: action.payload,
      };
    case USUARIO_LOGIN_GOOGLE_FAIL:
      return {
        loading: false,
        usuario: null,
      };
    case USUARIO_LOGIN_FACEBOOK_REQUEST:
      return {
        loading: true,
        usuario: null,
      };
    case USUARIO_LOGIN_FACEBOOK_SUCCESS:
      return {
        loading: false,
        usuario: action.payload,
      };
    case USUARIO_LOGIN_FACEBOOK_FAIL:
      return {
        loading: false,
        usuario: null,
      };
    case USUARIO_RELOAD_REQUEST:
      return { ...state, loading: true };
    case USUARIO_RELOAD_SUCCESS:
      return { loading: false, usuario: { cliente: action.payload } };
    case USUARIO_RELOAD_FAIL:
      return { ...state, loading: false };
    case USUARIO_VINCULADO_AO_COMPLETAR_CADASTRO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          cliente: {
            ...state.usuario.cliente,
            clientePossuiVinculoLoja: true,
          },
        },
        loading: false,
      };
    case USUARIO_ACEITE_TERMOS:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          cliente: {
            ...state.usuario.cliente,
            aceite: true,
          },
        },
        loading: false,
      };
    default:
      return state;
  }
};
