import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { Container } from '../../styles/shared/Container';

// import PadraoPlataforma from './plataforma';
// import Hotsite from './hotsite';

const PadraoPlataforma = React.lazy(() => import('./plataforma'));
const Hotsite = React.lazy(() => import('./hotsite'));

const Plataforma = () => {
  const { pathname } = window.location;

  return (
    <BrowserRouter basename={pathname.split('/')[1]}>
      <Switch>
        <Suspense
          fallback={
            <Container height="100vh">
              <Spinner
                style={{ width: '10rem', height: '10rem' }}
                color="danger"
              />
            </Container>
          }
        >
          <Route path="/" exact>
            <PadraoPlataforma />
          </Route>
          <Route path="/:loja">
            <Hotsite />
          </Route>
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};

export default Plataforma;
