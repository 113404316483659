export const vouchersReducer = (
  state = {
    gerados: { totalItems: 0, data: [] },
    resgatados: { totalItems: 0, data: [] },
  },
  action
) => {
  switch (action.type) {
    case 'GET_LISTA_VOUCHERS_SUCCESS':
      return {
        ...state,
        gerados: {
          totalItems: action.payload.totalItems,
          data: action.payload.data,
        },
      };
    case 'GET_PREMIOS_RESGATADOS_SUCCESS':
      return {
        ...state,
        resgatados: {
          totalItems: action.payload.totalItems,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};
