import React, { useState, useRef, useEffect } from 'react';
import Icon from '../../assets/icons/error_outline-24px.svg';

// components
import { Container } from './styles';

const TooltipPng = ({ id, texto }) => {
  const [showTooltipMessage, setShowTooltipMessage] = useState(false);
  const iconRef = useRef(null);

  useEffect(() => {
    const outsideClick = (event) => {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowTooltipMessage(false);
      }
    };

    if (showTooltipMessage) {
      document.addEventListener('mousedown', outsideClick);
    } else {
      document.removeEventListener('mousedown', outsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', outsideClick);
    };
  }, [showTooltipMessage]);

  return (
    <Container>
      <img
        ref={iconRef}
        onClick={() => {
          setShowTooltipMessage(true);
        }}
        className="tooltip-icon"
        style={{ width: '15px', cursor: 'pointer' }}
        id={id}
        src={Icon}
        alt=""
      />
      {texto && (
        <p
          className={`tooltip-message ${
            showTooltipMessage ? 'showTooltipMessage' : ''
          }`}
        >
          {texto}
        </p>
      )}
    </Container>
  );
};

export default TooltipPng;
