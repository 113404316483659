import styled from 'styled-components';

export const Container = styled.div`
  max-width: 30rem;

  > label {
    display: flex;
    flex-direction: column;

    div.input {
      display: flex;

      input#termos {
        flex: 1.5;
        transition: 0.2s all ease-in-out;
        height: 3.5rem !important;
        width: 3.5rem !important;
        display: inline-block;
        border: 1px solid #40e0d0 !important;
      }
    }

    div.label-text {
      width: 25rem;
      max-width: calc(100% - 3.5rem);
      margin-left: 1.5rem;

      p.label {
        font-size: 1.6rem;
        text-align: justify;
        text-justify: inter-word;
        display: inline-block;
        width: 100%;

        @media (max-width: 768px) {
          text-align: justify;
          font-size: 1.2rem;
        }
      }
    }

    div.errors {
      color: red;

      p {
        text-align: left;
        font-size: 1.6rem;
      }
    }
  }
`;
