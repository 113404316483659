export const premioReducer = (
  state = { premio: {}, loading: false },
  action
) => {
  switch (action.type) {
    case 'PREMIO_REQUEST':
      return {
        premio: {},
        loading: true,
      };
    case 'PREMIO_SUCCESS':
      return {
        premio: action.payload,
        loading: false,
      };
    case 'PREMIO_FAIL':
      return {
        premio: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
