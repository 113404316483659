import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'reactstrap';

// actions

import { googleLogin } from '../../store/actions/googleFacebookLoginActions';
import { abrirModal } from '../../store/actions/modalActions';

const GoogleLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, usuario } = useSelector((state) => state.usuarioLogin);

  const { plataforma, loja } = localStorage.getItem('@Plataforma:Loja')
    ? JSON.parse(localStorage.getItem('@Plataforma:Loja'))
    : { plataforma: '', loja: '' };

  let code = window.location.search.split('&')[0].split('=')[1];
  if (code) code = code.replace('%2F', '/');

  useEffect(() => {
    dispatch(googleLogin(code));
  }, [dispatch, code]);

  useEffect(() => {
    if (usuario && usuario.cliente.clienteId === 0) {
      const { plataforma, loja } = JSON.parse(
        localStorage.getItem('@Plataforma:Loja')
      );
      dispatch(abrirModal('register1'));
      history.push(`/${plataforma}/${loja}/home`);
    }
  }, [dispatch, loading, usuario]);

  return (
    <div
      style={{
        background: 'white',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
      }}
    >
      {loading ? (
        <Spinner style={{ width: '10rem', height: '10rem' }} color="danger" />
      ) : usuario !== null && usuario.cliente.clienteId === 0 ? (
        <>
          <h3>Nenhuma usuário cadastrado com o email informado.</h3>
          <br />
          <span>Tente novamente.</span>
          <br />
          <a href={`/${plataforma}/${loja}`}>
            <h1>&#8592; Voltar</h1>
          </a>
        </>
      ) : usuario === null ? (
        <>
          <h3>Não foi possível fazer o login.</h3>
          <br />
          <span>Tente novamente.</span>
          <br />
          <a href={`/${plataforma}/${loja}`}>
            <h1>&#8592; Voltar</h1>
          </a>
        </>
      ) : (
        <>
          <h3>Seja bem-vindo, {usuario.cliente.nome}!</h3>
        </>
      )}
    </div>
  );
};

export default GoogleLogin;
