export const plataformaLojaReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SALVA_PLATAFORMA':
      return {
        ...state,
        plataformaPathname: action.payload,
        lojaPathname: '',
      };
    case 'SALVA_LOJA':
      return {
        ...state,
        plataformaPathname: action.payload.plataforma,
        lojaPathname: action.payload.loja,
      };
    case 'PLATAFORMA_DATA_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'SALVA_PLATAFORMA_DATA':
      return {
        ...state,
        plataformaPathname: action.payload.pathnames.plataforma,
        lojaPathname: action.payload.pathnames.loja,
        plataformaData: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
