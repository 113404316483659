import styled from 'styled-components';

export const Wrapper = styled.div`
  h2 {
    font-weight: bold;
    margin: 3rem 0;
  }

  p {
    margin: 1.4rem 0;
    font-size: 1.4rem;
  }
`;
