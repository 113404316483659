import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from '../../styles/shared/Container';
import { Button } from '../../styles/shared/Button';
import { CloseModalTermosPoliticas, Content } from './styles';
import { fecharModal } from '../../store/actions/modalActions';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import Termos from '../Termos/termos';
import Politicas from '../Termos/politicas';
import api from '../../api/api';
import { toast } from 'react-toastify';

const AceiteTermos = () => {
  const dispatch = useDispatch();
  const [showModalTermosPoliticas, setShowModalTermosPoliticas] =
    useState(false);
  const [termosOuPoliticas, setTermosOuPoliticas] = useState('');
  const { usuario } = useSelector((state) => state.usuarioLogin);

  const handleAceite = useCallback(async (data) => {
    api
      .put('/hotsite/cliente/aceite/atualizar')
      .then(() => {
        toast.success('Operação realizada com sucesso!');
        dispatch({ type: 'USUARIO_ACEITE_TERMOS' });
        dispatch(fecharModal);
        const newUserObj = usuario;
        newUserObj.cliente.aceite = true;
        console.log(newUserObj);
        localStorage.setItem('Hotsite:Usuario', JSON.stringify(newUserObj));
      })
      .catch((error) =>
        toast.error(
          error.response?.data?.error[0]?.message ||
            'Não foi possível. Tente novamente.'
        )
      );
  }, []);

  const onCancelarClick = () => {
    dispatch(fecharModal);
  };

  const showModal = (component) => {
    setTermosOuPoliticas(component);
    setShowModalTermosPoliticas(true);
  };

  return (
    <Container
      width="30rem"
      padding="3rem 1rem !important"
      backgroundColor="#fff"
    >
      <Content>
        <h4>Termos de uso</h4>
        <div className="info">
          Confirme que você concorda com nossos{' '}
          <span
            className="show-modal"
            onClick={() => {
              showModal('termos');
            }}
          >
            Termos
          </span>
          ,{' '}
          <span
            className="show-modal"
            onClick={() => {
              showModal('politicas');
            }}
          >
            Política de Dados e Política de Cookies
          </span>
          . Você pode receber notificações por E-mail e SMS e pode cancelar isso
          quando quiser.
        </div>
        <ModalTermosPoliticas
          showModalTermosPoliticas={showModalTermosPoliticas}
          setShowModalTermosPoliticas={setShowModalTermosPoliticas}
          termosOuPoliticas={termosOuPoliticas}
        />
        <Button type="button" onClick={handleAceite} margin="1rem 0">
          Sim
        </Button>
        <Button
          type="button"
          onClick={onCancelarClick}
          backgroundColor="#a3617233"
          textColor="#666"
        >
          Não
        </Button>
      </Content>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    borderRadius: '3rem',
    transition: 'all 0.2s ease-in-out',
  },
}));

const ModalTermosPoliticas = ({
  showModalTermosPoliticas,
  setShowModalTermosPoliticas,
  termosOuPoliticas,
}) => {
  const classes = useStyles();

  const closeModal = () => {
    setShowModalTermosPoliticas(false);
  };

  return (
    <Dialog
      open={showModalTermosPoliticas}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle style={{ position: 'relative' }}>
        <CloseModalTermosPoliticas onClick={closeModal}>
          <div>
            <span>X</span>
          </div>
        </CloseModalTermosPoliticas>
      </DialogTitle>
      <DialogContent style={{ position: 'relative' }}>
        {termosOuPoliticas === 'termos' ? <Termos /> : <Politicas />}
      </DialogContent>
    </Dialog>
  );
};

export default AceiteTermos;
