import React from 'react';
import { Wrapper } from './styles';

const Politicas = () => {
  return (
    <Wrapper>
      <h1>POLÍTICA DE PROTEÇÃO DE DADOS</h1>
      <p>
        A sua privacidade é muito importante para nós. Desenvolvemos esta
        Política de Proteção de Dados para que você entenda como coletamos,
        usamos, armazenamos, compartilhamos, transmitimos, transferimos,
        excluímos ou processamos (coletivamente, “processamos”) seus dados
        pessoais. Esta Política de Proteção de Dados descreve as medidas que
        tomamos para garantir a proteção de seus dados pessoais. Também
        informamos como você pode entrar em contato para que possamos responder
        quaisquer perguntas que você possa ter sobre a proteção de dados.
      </p>
      <h2>GLOSSÁRIO</h2>
      <p>Abaixo algumas expressões</p>
      <p>Agentes de Tratamento: o controlador e o operador</p>
      <p>
        Aplicação Web: refere-se a qualquer programa de computador que é
        acessado por meio de uma conexão de rede utilizando-se do paradigma
        cliente-servidor, onde o lado PARCEIRO (ou lado da interface do usuário)
        é executado em um navegador web utilizando protocolo de aplicação HTTP
        ou HTTPS, ou equivalente, podendo envolver ainda situações onde uma
        pequena parte do programa é descarregado, instalado e executado
        parcialmente no dispositivo, mas o processamento principal é executado
        em um ou mais servidores externos, por intermédio de uma conexão de
        rede, principalmente utilizando Internet.
      </p>
      <p>
        Aplicação Mobile: refere-se a qualquer programa de computador utilizando
        para uma função específica em dispositivos móveis, tais como tablets e
        smartphones. Disponíveis por meio de sistemas operacionais específicos,
        prestam-se a objetivos previamente estabelecidos por seu desenvolvedor.
      </p>
      <p>
        Atualização tecnológica: incremento nas funcionalidades ou tecnologia do
        software, resultando ou não em nova versão, disponibilizada a critério
        do SIBRARE.
      </p>
      <p>
        Consentimento: manifestação livre, informada e inequívoca pela qual o
        titular concorda com o tratamento de seus dados pessoais para uma
        finalidade determinada
      </p>
      <p>
        Cookies: Um cookie é uma tecnologia que permite ao nosso site armazenar
        tokens de informação (um ‘identificador’) no seu navegador, usados
        apenas pelo nosso site. Os cookies não são usados para determinar a
        identidade pessoal de quem está apenas visitando nosso site. Eles servem
        para nos ajudar a rastrear padrões de tráfego, para determinar a
        localização e o idioma preferidos de um usuário, para que possamos
        direcioná-los para a página inicial correta quando visitarem o Nosso
        Site.
      </p>
      <p>
        Dado Pessoal: qualquer informação relacionada a uma pessoa natural
        identificada ou identificável. Uma pessoa natural identificável é alguém
        que pode ser identificada, direta ou indiretamente, principalmente por
        meio de referência a um identificador único como nome, número de
        identificação, dado locacional, identificador eletrônico ou um ou mais
        fatores específicos à identidade física, psicológica, genética, mental,
        econômica, cultural ou social da pessoa natural – Definição baseada na
        Lei Geral de Proteção de Dados.
      </p>
      <p>
        Termos de Uso: documento que declara os procedimentos e processos na
        utilização deste software, limita a responsabilidade legal do Veran, e
        dos usuários, além de explicitar questões sobre o nível de serviço e os
        elementos protegidos legalmente em relação à propriedade intelectual.
      </p>
      <p>
        Tratamento de dados: qualquer operação ou conjunto de operações as quais
        são realizadas em um dado pessoal ou em conjuntos de dados pessoais, por
        meios automatizados ou não, tais como catalogação, gravação,
        organização, estruturação, armazenamento, adaptação ou alteração,
        coleta, consulta, uso, revelação por meio de transmissão, disseminação
        ou qualquer forma que torne disponível, alinhamento, combinação,
        restrição, apagamento ou destruição – Lei Geral de Proteção de Dados.
      </p>
      <p>
        Responsabilidade Social: Segundo o Livro Verde da Comissão Europeia
        (2001), trata-se de um conceito, segundo o qual, as empresas decidem,
        numa base voluntária, contribuir para uma sociedade mais justa e para um
        ambiente mais limpo. Com base nesse pressuposto, a gestão das empresas
        não pode, e/ou não deve, ser norteada apenas para o cumprimento de
        interesses dos proprietários das mesmas, mas também pelos de outros
        detentores de interesses, como, por exemplo, os trabalhadores, as
        comunidades locais.
      </p>
      <h2>ESCOPO</h2>
      <p>
        A Política de Proteção de Dados se aplica ao Fidelêco, para todas as
        dimensões e atividades. Esta política se aplica ao processamento de
        dados pessoais coletados pelo Fidelêco, direta ou indiretamente, de
        todos os indivíduos que acessem os sites ou dados inseridos na
        plataforma, incluindo, “Dados Pessoais” definidos como quaisquer dados
        que se relacionem com um indivíduo identificado ou identificável ou uma
        pessoa que possa ser identificada por meios razoavelmente prováveis de
        serem usados.
      </p>
      <p>
        Ainda, segundo a própria lei geral de proteção de dados, é importante
        apontar que você é o titular dos seus dados e que o controlar é a pessoa
        natural ou jurídica, a quem competem as decisões referentes ao
        tratamento de dados pessoais coletados.
      </p>
      <p>
        Nesta Política, “você” e “seu” indica qualquer indivíduo que utiliza a
        plataforma Fidelêco. “Nós”, “nosso” e significa o FIDELÊCO.
      </p>
      <h2>RESUMO DO QUE VOCÊ ENCONTRARÁ NESSA POLÍTICA:</h2>
      <p>COLETA E PROCESSAMENTO DE USO DOS SEUS DADOS PESSOAIS</p>
      <p>LEALDADE, JUSTIÇA E TRANSPARÊNCIA</p>
      <p>OBJETIVO LEGÍTIMO, LIMITAÇÃO E MINIMIZAÇÃO DE DADOS</p>
      <p>PRECISÃO DOS DADOS E LIMITAÇÃO DE ARMAZENAMENTO</p>
      <p>SEGURANÇA DOS SEUS DADOS PESSOAIS</p>
      <p>DIVULGAÇÃO E COMPARTILHAMENTO DOS SEUS DADOS PESSOAIS</p>
      <p> FINALIDADES DO TRATAMENTO</p>
      <p> COOKIES</p>
      <p>SEUS DIREITOS</p>
      <p> ATUALIZAÇÃO </p>
      <p>ENTRE EM CONTATO</p>
      <br />
      <p>
        Para o progresso no objetivo pelo qual a plataforma foi criada, os
        agentes de tratamento dos dados, bem como os titulares, são
        identificados na utilização da plataforma, em conformidade com a Lei
        Geral de Proteção de Dados, da seguinte forma:
      </p>
      <ul>
        <li>
          Controlador: pessoa natural ou jurídica, de direito público ou
          privado, a quem competem as decisões referentes ao tratamento de dados
          pessoais. Neste caso, o Fidelêco é o controlador de dados.
        </li>
        <li>
          Operador: pessoa natural ou jurídica, de direito público ou privado,
          que realiza o tratamento de dados pessoais em nome do controlador.
          Neste caso, o Fidelêco também é operador, pois o tratamento de dados
          ocorre por meio da plataforma.
        </li>
        <li>
          Titular: pessoa natural a quem se referem os dados pessoais que são
          objeto de tratamento. Neste caso, os Usuários.
        </li>
      </ul>

      <h2>
        COLETA E PROCESSAMENTO DE USO DE SEUS DADOS PESSOAIS – CONFORMIDADE COM
        A LEI GERAL DE PROTEÇÃO DE DADOS BRASILEIRA
      </h2>
      <p>
        Comprometemo-nos a cumprir a legislação aplicável em relação a dados
        pessoais e asseguraremos que os dados pessoais sejam coletados e
        processados de acordo com as disposições da lei brasileira de proteção
        de dados.
      </p>

      <h2>LEALDADE, JUSTIÇA E TRANSPARÊNCIA</h2>
      <p>
        Nós não coletamos ou processamos dados pessoais sem ter uma razão legal
        para fazê-lo. Podemos ter que coletar e processar seus dados pessoais
        quando necessário para a execução de um contrato do qual você é parte,
        ou quando for necessário para o cumprimento de uma obrigação legal à
        qual estamos sujeitos ou quando necessário, com o seu consentimento
        prévio. Também podemos coletar e processar seus dados pessoais para os
        interesses legítimos do Fidelêco, exceto quando esses interesses forem
        anulados por seus interesses ou direitos e liberdades fundamentais.
      </p>
      <p>
        Ao coletar e processar seus dados pessoais, forneceremos a você um aviso
        de informações justo e completo ou uma declaração de privacidade sobre
        quem é responsável pelo processamento de seus dados pessoais, para quais
        fins seus dados pessoais são processados, quem são os destinatários,
        quais são seus direitos e como exercê-los, etc., a menos que seja
        impossível, ou exigir esforços desproporcionais para fazê-lo. Quando
        exigido pela lei aplicável, buscaremos seu consentimento prévio (por
        exemplo, antes de coletar quaisquer dados confidenciais pessoais).
      </p>

      <h2>OBJETIVO LEGÍTIMO, LIMITAÇÃO E MINIMIZAÇÃO DE DADOS</h2>
      <p>
        Seus dados pessoais são coletados para fins específicos, explícitos e
        legítimos e não são processados de maneira incompatível com esses
        propósitos.
      </p>
      <p>
        Quando a Fidelêco age para seus próprios propósitos, seus dados pessoais
        são processados principalmente para, mas não limitados aos seguintes
        propósitos: gestão de segurança de pessoal, fornecimento de ferramentas
        de TI ou sites internos e quaisquer outras soluções digitais ou
        plataformas colaborativas, gerenciamento de suporte de TI, gerenciamento
        de segurança, gerenciamento de segurança de informações, gerenciamento
        de relacionamento com cliente, gerenciamento de vendas e marketing,
        gerenciamento de suprimentos, comunicação interna e externa e gestão de
        eventos ou quaisquer outros requisitos legais e operações de análise de
        dados, análise de mercado, dentre outros.
      </p>
      <p>
        Ao fornecer nossos serviços para o benefício de nossos clientes ou entre
        nós, também podemos processar dados pessoais em nome de um Controlador
        essencialmente para a operação, gerenciamento, desempenho e entrega
        eficazes dos nossos serviços. Garantiremos que os dados pessoais
        processados sejam adequados, relevantes e limitados ao necessário para
        as finalidades para as quais são processados.
      </p>

      <h2>PRECISÃO DOS DADOS E LIMITAÇÃO DE ARMAZENAMENTO</h2>
      <p>
        O Fidelêco manterá os dados pessoais processados com precisão e, quando
        necessário, atualizados. Além disso, mantemos apenas dados pessoais pelo
        tempo necessário para as finalidades para as quais são processados. O
        FIDELÊCO atuará de acordo com as instruções de seus clientes, a fim de
        auxiliá-los no cumprimento desta obrigação.
      </p>

      <h2>SEGURANÇA DOS SEUS DADOS PESSOAIS</h2>
      <p>
        Implementamos medidas técnicas e organizacionais apropriadas para
        proteger os Dados pessoais contra alteração ou perda acidental ou
        ilegal, ou de uso, divulgação ou acesso não autorizado, de acordo com
        nossa Política de Segurança de Informação. Tomamos, quando apropriado,
        todas as medidas razoáveis para implementar as salvaguardas necessárias
        e proteger o processamento de dados pessoais.
      </p>

      <h2>DIVULGAÇÃO E COMPARTILHAMENTO DOS SEUS DADOS PESSOAIS</h2>
      <p>
        <strong>
          PODEMOS, NO CURSO NORMAL DE NOSSOS NEGÓCIOS E PARA FINS DE
          PROCESSAMENTO, COMPARTILHAR SEUS DADOS PESSOAIS COM O PESSOAL
          RELEVANTE DENTRO DO FIDELÊCO, OU COM NOSSOS COLABORADORES DEVIDAMENTE
          AUTORIZADOS, CONTRATADOS/SUBCONTRATADOS, BEM COMO EMPRESAS PARCEIRAS E
          EMPRESAS DO MESMO GRUPO ECONÔMICO,
        </strong>{' '}
        para assegurar a consistência em nossas atividades de contratação,
        maximizamos a qualidade e a eficiência de nossos serviços e de nossas
        operações comerciais.
      </p>
      <p>
        Ainda, nós poderemos utilizar e/ou divulgar depoimentos de usuários
        presentes em ambientes disponibilizados, Aplicativos, Portais e/ou redes
        sociais para a divulgação da Solução Fidelêco.
      </p>
      <p>
        Ainda, O Fidelêco poderá, entretanto, compartilhar informações e Dados
        Pessoais dos Usuários, nas seguintes circunstâncias, sem que tenha
        obrigação de notificar os referidos Utilizadores: (I) Comerciantes: O
        FIdelêco poderá providenciar Dados Pessoais e outras informações dos
        Utilizadores aos Comerciantes (a) para que os Comerciantes possam
        entregar pontos, redimir recompensas, enviar ofertas, verificar fraudes,
        solucionar problemas ou se comunicar com os Utilizadores; (b) em conexão
        com processos ou ordens judiciais relacionadas ao Fidelêco ou aos
        Comerciantes; (c) para facilitar o relacionamento entre os Comerciantes
        e os Utilizadores; (d) de qualquer outra forma prevista nesta Política
        de Privacidade. (II) Sociedades Afiliadas: O Fidelêco poderá
        providenciar Dados Pessoais e outras informações dos Utilizadores para
        as suas Sociedades Afiliadas para os fins estabelecidos nesta Política
        de Privacidade. (IV) Consultores e Terceiros: O Fidelêco poderá
        contratar terceiros para realizar certas funções relacionadas ao
        Serviço, Website, Aplicativo e/ou aos negócios do Fidelêco, tais como,
        exemplificadamente: manter bases e/ou bancos de dados, enviar e-mails
        e/ou mensagens instantâneas (ex. SMS, Whatsapp, Messenger, etc.),
        desenvolver ou disponibilizar partes do Serviço, Website e/ou
        Aplicativo, e realizar a manutenção e/ou garantir a segurança do
        Serviço, Website e/ou Aplicativo. Nestes casos, o Fidelêco só irá
        providenciar a tais terceiros as informações necessárias para que estes
        prestem os serviços para que foram contratados de forma adequada. O
        Fidelêco também compartilha dados coletados automaticamente dos
        Utilizadores (pontos obtidos, atividades promocionais, recompensas
        redimidas) com os Comerciantes, com terceiros servidores de anúncios e
        com terceiros anunciantes ligados ao Serviço, Website, Aplicativo e/ou
        Fidelêco. Tais terceiros podem ligar tais informações e dados com
        informações e dados previamente coletados dos Utilizadores por tais
        terceiros, de acordo com as políticas de privacidade destes terceiros. O
        Fidelêco recomenda que os Utilizadores verifiquem as políticas de
        privacidade de tais terceiros regularmente.
      </p>
      <p>
        Afim de atender aos melhores padrões internacionais de segurança e
        proteção de dados, o Fidelêco pode utilizar parceiros localizados no
        exterior para disponibilizar infraestrutura técnica necessária à oferta
        da Solução Fidelêco.
      </p>
      <p>
        Também podemos ser obrigados a divulgar dados pessoais a autoridades
        reguladoras, tribunais e agências governamentais quando exigido por lei,
        regulamento ou processo legal, ou defender os interesses, direitos ou
        propriedade do Fidelêco ou de terceiros relacionados. Caso contrário,
        não compartilharemos seus dados pessoais com outras partes, a menos que
        você os solicite ou nos tenha dado aprovação prévia para tal
        compartilhamento.
      </p>
      <h2>FINALIDADES DO TRATAMENTO</h2>
      <p>Nós tratamos dados pessoais com as seguintes finalidades: </p>
      <p>(i) Cadastro do Usuário junto o Fidelêco;</p>
      <p>
        (ii) Quando necessário para permitir a efetiva disponibilização da
        Solução Fidelêco e aplicações relacionadas;
      </p>
      <p>
        (iii) Análises de risco, sistemas antifraude e de prevenção a ilícitos,
        de acordo com a legislação e regulamentação aplicável;
      </p>
      <p>(iv) Precificação da Solução Fidelêco;</p>
      <p>
        (v) Aperfeiçoamento e atualização da Solução Fidelêco, bem como para
        criação de novos produtos e funcionalidades;
      </p>
      <p>
        (vi) Oferta de produtos e serviços de parceiros do Fidelêco e/ou
        empresas pertencentes ao seu grupo econômico; e
      </p>
      <p>
        (vii) Nos casos previstos na legislação e regulamentação aplicável ou
        mediante ordem de autoridades competentes.
      </p>
      <p>
        Portanto, ao aceitar estes termos você nos fornece seu CONSENTIMENTO
        para as finalidades acima apontadas e as demais descritas nesta
        política.
      </p>
      <h2>COOKIES</h2>
      <p>
        Como muitas empresas, alguns de nossos sites podem usar “cookies”.
        Cookies são partes do texto que são colocadas no disco rígido do seu
        computador quando você visita determinados sites. Podemos usar cookies
        para nos informar, por exemplo, se você já nos visitou antes ou se você
        é um novo visitante e nos ajuda a identificar recursos nos quais você
        possa ter o maior interesse. Os cookies podem aprimorar sua experiência
        on-line, salvando suas preferências enquanto você visita um site.
      </p>
      <p>
        Informaremos quando você visitar nossos sites que tipos de cookies
        usamos e como desativar esses cookies. Quando exigido por lei, você
        poderá visitar nossos sites e recusar o uso de cookies a qualquer
        momento em seu computador.
      </p>

      <h2>SEUS DIREITOS</h2>
      <p>
        O FIDELÊCO está comprometida em garantir a proteção de seus direitos sob
        as leis aplicáveis. Você encontrará abaixo uma relação resumindo seus
        diferentes direitos:
      </p>
      <p>
        <strong>Direito de acesso</strong>
      </p>
      <p>
        - Você pode solicitar acesso aos seus dados pessoais. Você também pode
        solicitar a correção de dados pessoais imprecisos ou ter dados pessoais
        incompletos completados.
      </p>
      <p>
        - Você pode solicitar qualquer informação disponível sobre a fonte dos
        dados pessoais, e você também pode solicitar uma cópia dos seus dados
        pessoais que estão sendo processados pelo Fidelêco, bem como a
        identificação e informações do controlador.
      </p>
      <p>
        <strong>Direito de ser esquecido</strong>
      </p>
      <p>
        Seu direito de ser esquecido lhe dá o direito de solicitar a exclusão de
        seus dados pessoais nos casos em que:
      </p>
      <p>- os dados não são mais necessários;</p>
      <p>- você escolhe retirar seu consentimento;</p>
      <p>
        - você se opõe ao processamento de seus dados pessoais - por meios
        automatizados usando especificações técnicas;
      </p>
      <p>- seus dados pessoais foram processados ilegalmente;</p>
      <p>- existe uma obrigação legal de apagar seus dados pessoais;</p>
      <p>
        - a eliminação é necessária para garantir a conformidade com as leis
        aplicáveis
      </p>
      <p>
        <strong>Direito de restrição de processamento</strong>
      </p>
      <p>
        Você pode solicitar que o processamento de seus dados pessoais seja
        restrito nos casos em que:
      </p>
      <p>- você contesta a precisão dos dados pessoais;</p>
      <p>
        - o Fidelêco não precisa mais dos dados pessoais, para os propósitos do
        processamento;
      </p>
      <p>- você se opôs ao processamento por motivos legítimos.</p>
      <p>
        <strong>Direito à portabilidade de dados</strong>
      </p>
      <p>
        Você pode solicitar, quando aplicável, a portabilidade de seus Dados
        Pessoais que forneceu ao Fidelêco, em um formato estruturado, comumente
        usado e legível por máquina.{' '}
      </p>
      <p>
        Você tem o direito de transmitir esses dados para outro Controlador sem
        impedimentos do Fidelêco onde:
      </p>
      <p>
        - o processamento dos seus Dados Pessoais é baseado no consentimento ou
        em um contrato; e
      </p>
      <p>- o processamento é realizado por meios automatizados.</p>
      <p>
        Você também pode solicitar que seus dados pessoais sejam transmitidos a
        terceiros de sua escolha (quando tecnicamente viável).
      </p>

      <p>
        <strong>
          Direito de oposição ao processamento para fins de marketing direto
        </strong>
      </p>
      <p>
        Você pode se opor (ou seja, exercer o seu direito de “optar por não
        participar”) do processamento de seus dados pessoais, particularmente em
        relação à criação de perfis ou às comunicações de marketing. Quando
        processamos seus dados pessoais com base em seu consentimento, você pode
        retirar seu consentimento a qualquer momento.
      </p>

      <p>
        <strong>Direito de não estar sujeito a decisões automatizadas</strong>
      </p>
      <p>
        Você tem o direito de não estar sujeito a uma decisão baseada
        exclusivamente no processamento automatizado, incluindo a criação de
        perfil, que tem um efeito legal sobre você ou afeta você de maneira
        significativa.
      </p>
      <p>
        Para exercer esses direitos, você pode enviar sua Solicitação ou
        Reclamação seguindo o procedimento estabelecido nas declarações de
        privacidade trazidas à sua atenção no momento da coleta de seus dados
        pessoais ou enviando um e-mail para o seguinte endereço de e-mail:
        [contato controlador]
      </p>

      <h2>ATUALIZAÇÃO</h2>
      <p>
        Podemos atualizar esta Política de Proteção de Dados oportunamente, à
        medida que nossas mudanças nos negócios ou requisitos legais mudam. Se
        fizermos alterações significativas a esta política, publicaremos um
        aviso em nosso site quando as alterações entrarem em vigor e, quando
        apropriado, enviaremos uma comunicação direta a você sobre a alteração.
      </p>

      <h2>ENTRE EM CONTATO</h2>
      <p>
        Se você tiver dúvidas sobre a coleta e o processamento de seus dados
        pessoais pelo Fidelêco, poderá enviar suas dúvidas ou reclamações
        seguindo o procedimento estabelecido nas declarações de privacidade que
        lhe foram comunicadas no momento da coleta de seus dados pessoais ou
        enviando um e-mail para o endereço de e-mail: guilherme@fideleco.com.br
      </p>
      <br />
      <p>Jurídico Synapses Holding - juridico@synapses.sa.com</p>
    </Wrapper>
  );
};

export default Politicas;
