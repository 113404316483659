// action types
import { ABRE_MODAL, FECHA_MODAL } from '../types/modal';

export const abrirModal = (componente) => (dispatch) => {
  dispatch({
    type: ABRE_MODAL,
    payload: componente,
  });
};

export const fecharModal = {
  type: FECHA_MODAL,
};
