import { createGlobalStyle } from 'styled-components';

import ObjectiveRegular from '../assets/fonts/Objective_Woff2/Objective-Regular.woff2';
import ObjectiveBold from '../assets/fonts/Objective_Woff2/Objective-Bold.woff2';
import Lena from '../assets/fonts/Lena.ttf';

const GlobalStyles = createGlobalStyle`
  @font-face {
     font-family: 'Objective';
     font-display: swap;
     src: url(${ObjectiveRegular});
  }

  @font-face {
     font-family: 'Objective Bold';
     font-display: swap;
     src: url(${ObjectiveBold});
  }

  @font-face {
     font-family: 'Lena';
     font-display: swap;
     src: url(${Lena});
  }


    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
    }

    html {
        font-size: 62.5%;
        height: 100%;
    }

    body {
        font-family: 'Objective', sans-serif !important;
        font-size: 1.6rem;
        background: black;
        height: 100%;
    }

    #root {
        // max-width: 1600px;
        margin: 0 auto;
        height: 100%;
    }

    ul {
        list-style-type: none;
        text-decoration: none;
    }

    button:focus{
      outline: none;
    }


    // Scrollbar dos modais
    .MuiDialogContent-root {
        &::-webkit-scrollbar {
            width: 4px;
        }

       &::-webkit-scrollbar-track {
        background: #13131313;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #13131399;
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
        }
    }
`;

export default GlobalStyles;
