import styled from 'styled-components';

export const Container = styled.section`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#eee'};
  padding: ${(props) => (props.padding ? props.padding : '2rem 4rem')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  color: ${(props) => (props.textColor ? props.textColor : '#000000')};
  width: ${(props) => (props.width ? props.width : '100%')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  height: ${(props) => (props.height ? props.height : '100%')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
  text-align: center;
  padding: 6rem 12rem;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};

  @media (max-width: 768px) {
    padding: 4rem 4rem;
  }

  h1 {
    font-size: 4rem;
    text-transform: uppercase;
  }

  h2 {
    margin: 4rem 0;
  }

  h3 {
    font-size: 2.4rem;
  }

  a {
    color: #fd5958;
    transition: 0.2s all ease-in-out;

    &:hover {
      filter: saturate(200%);
    }
  }

  div.mvertical-8 {
    margin: 8rem 0;
  }
`;
