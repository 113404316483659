import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  & + div {
    margin-top: 30px;
  }

  .label {
    display: flex;
    align-items: center;

    label {
      margin: 0;
    }
  }

  .input-title {
    font-size: 1.2rem;
    font-weight: 400;
    color: #5d5d5d;
    transition: color 0.5s;

    ${(props) =>
      props.isFocused &&
      css`
        color: ${(props) => props.theme.mainColor};
        font-weight: 600;
      `}
  }

  .input-contato {
    width: 100%;
    height: 30px;
    border: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;
    outline: none;
    transition: border-bottom 0.5s;
    padding: 1rem 0;
    font-size: 2rem;
    letter-spacing: 0.4rem;
    font-weight: bold;

    &::placeholder {
      font-size: 1.6rem;
    }

    ${(props) =>
      props.isFocused &&
      css`
        border-bottom: 2px solid ${(props) => props.theme.mainColor};
      `};
  }

  .icon-senha {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;

    img {
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      width: 24px;
      height: 24px;

      &:hover {
        filter: saturate(180%);
      }
    }
  }

  .erro {
    color: red;
  }
`;
