import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/';
import GlobalStyles from './styles/globalStyles';

import ThemeContainer from './styles/theme';

import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <Provider store={store}>
    <ThemeContainer>
      <GlobalStyles />
      <App />
    </ThemeContainer>
  </Provider>,
  document.getElementById('root')
);
