import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Plataforma from './Plataforma';
import GoogleLogin from '../pages/GoogleLogin';
import FacebookLogin from '../pages/FacebookLogin';

import { Container } from '../styles/shared/Container';

export default function Routes(props) {
  return (
    <BrowserRouter>
      <Switch>
        {/* Fideleco padrão. URL não informa nem plataforma, nem loja. */}
        <Route path="/" exact>
          <Container>
            <h1>Bem-vindo ao Fideleco.</h1>
            <h3>Visite a loja que você deseja.</h3>
          </Container>
        </Route>

        {/* Páginas de redirecionamento Google e Facebook (login). */}
        <Route path="/google-login" exact>
          <GoogleLogin />
        </Route>
        <Route path="/facebook-login" exact>
          <FacebookLogin />
        </Route>

        {/* Plataforma */}
        <Route path="/:plataforma">
          <Plataforma />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
