import { ABRE_MODAL, FECHA_MODAL } from '../types/modal';

export const modalReducer = (
  state = { open: false, componente: 'login' },
  action
) => {
  switch (action.type) {
    case ABRE_MODAL:
      return { open: true, componente: action.payload };
    case FECHA_MODAL:
      return { ...state, open: false };
    default:
      return state;
  }
};
