import React, { useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import InputCheckbox from '../InputCheckbox';
import Input from '../Input';

import { Container } from '../../styles/shared/Container';
import { Button } from '../../styles/shared/Button';
import { Content } from './styles';
import { fecharModal } from '../../store/actions/modalActions';
import {
  vincularCliente,
  desvincularCliente,
} from '../../store/actions/usuarioActions';

const Vincular = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        aceiteTermos: Yup.boolean().oneOf(
          [true],
          'Confirme que você concorda com os Termos.'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(vincularCliente(data.aceiteTermos));
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }, []);

  const onCancelarClick = () => {
    dispatch(fecharModal);

    //recarregar a pagina para que o freshchat pegue os dados atualizado para o chat
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Container
      width="30rem"
      padding="3rem 1rem !important"
      backgroundColor="#fff"
    >
      <Content>
        <h4>Gostaria de se vincular a essa loja?</h4>
        <Form
          className="form-contato"
          ref={formRef}
          onSubmit={handleSubmit}
          style={{ width: '100% !important' }}
        >
          <InputCheckbox
            name="aceiteTermos"
            type="checkbox"
            options={{
              label:
                'Ao se vincular, você concorda com nossos Termos, Política de Dados e Política de Cookies. Você pode receber notificações por E-mail e SMS e pode cancelar isso quando quiser.',
              id: 'termos',
            }}
          />

          <Button type="submit" margin="1rem 0">
            Sim
          </Button>
          <Button
            type="button"
            onClick={onCancelarClick}
            backgroundColor="#a3617233"
            textColor="#666"
          >
            Não
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

const Desvincular = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        senha: Yup.string().required('Senha obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(desvincularCliente(data.senha));
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }, []);

  const onCancelarClick = () => {
    dispatch(fecharModal);
  };

  const [mostraSenha, setMostraSenha] = useState(false);

  return (
    <Container
      width="30rem"
      padding="3rem 1rem !important"
      backgroundColor="#fff"
    >
      <Content>
        <h4>Tem certeza que quer se desvincular dessa loja?</h4>

        <Form className="form-contato" ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="senha"
            label="Senha"
            isPassword
            type={mostraSenha ? 'text' : 'password'}
            mostraSenha={mostraSenha}
            setMostraSenha={setMostraSenha}
            iconSenha
          />
          <Button type="submit" margin="1rem 0">
            Sim
          </Button>
          <Button
            type="button"
            onClick={onCancelarClick}
            backgroundColor="#a3617233"
            textColor="#666"
          >
            Não
          </Button>
        </Form>
        <span>
          <small>
            Essa ação o desvincula da loja atual, para excluir sua conta entre
            em contato com o Fideleco.
          </small>
        </span>
      </Content>
    </Container>
  );
};

export { Vincular, Desvincular };
