import React, { useEffect, useState, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

export default function CheckboxInput({
  name,
  options,
  children,
  defaultChecked,
  ...rest
}) {
  const inputRef = useRef(null);
  const [checked, setChecked] = useState(defaultChecked || false);
  const {
    fieldName,
    registerField,
    defaultValue = false,
    error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [checked, fieldName, registerField]);

  const onChange = (e) => {
    setChecked(!checked);
  };

  return (
    <Container>
      <label htmlFor={options.id} key={options.id}>
        <div className="input">
          <input
            ref={inputRef}
            value={checked}
            onChange={(e) => onChange(e)}
            checked={checked}
            type="checkbox"
            id={options.id}
            {...rest}
          />
          <div className="label-text">
            {options.label !== '' ? options.label : children}
          </div>
        </div>
        <div className="errors">{error && <p>{error}</p>}</div>
      </label>
    </Container>
  );
}
