import React from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

// actions
import { abrirModal, fecharModal } from '../../store/actions/modalActions';

const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  font-weight: bold;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2rem;
    line-height: 2rem;
    background: coral;
    width: 2.4rem;
    height: 2.4rem;
    padding: 1.4rem;
    border-radius: 50%;

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const Modal = ({ title, children }) => {
  const { innerWidth } = window;
  const padding = innerWidth > 414 ? 2 : 0;
  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      padding: theme.spacing(padding),
      borderRadius: '3rem',
      transition: 'all 0.2s ease-in-out',
    },
  }));

  const dispatch = useDispatch();
  const { open, componente } = useSelector((state) => state.modal);
  const { usuario } = useSelector((state) => state.usuarioLogin);

  const classes = useStyles();

  const closeModal = () => {
    dispatch(fecharModal);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle>
        <CloseModal onClick={closeModal}>
          <div>
            <span>X</span>
          </div>
        </CloseModal>
      </DialogTitle>
      <DialogContent style={{ position: 'relative' }}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
