export const USUARIO_LOGIN_SUCCESS = 'USUARIO_LOGIN_SUCCESS';
export const USUARIO_LOGIN_REQUEST = 'USUARIO_LOGIN_REQUEST';
export const USUARIO_LOGIN_FAIL = 'USUARIO_LOGIN_FAIL';
export const USUARIO_LOGOUT = 'USUARIO_LOGOUT';

export const USUARIO_LOGIN_GOOGLE_REQUEST = 'USUARIO_LOGIN_GOOGLE_REQUEST';
export const USUARIO_LOGIN_GOOGLE_SUCCESS = 'USUARIO_LOGIN_GOOGLE_SUCCESS';
export const USUARIO_LOGIN_GOOGLE_FAIL = 'USUARIO_LOGIN_GOOGLE_FAIL';

export const USUARIO_LOGIN_FACEBOOK_REQUEST = 'USUARIO_LOGIN_FACEBOOK_REQUEST';
export const USUARIO_LOGIN_FACEBOOK_SUCCESS = 'USUARIO_LOGIN_FACEBOOK_SUCCESS';
export const USUARIO_LOGIN_FACEBOOK_FAIL = 'USUARIO_LOGIN_FACEBOOK_FAIL';

export const USUARIO_ALTERA_SENHA_REQUEST = 'USUARIO_ALTERA_SENHA_REQUEST';
export const USUARIO_ALTERA_SENHA_SUCCESS = 'USUARIO_ALTERA_SENHA_SUCCESS';
export const USUARIO_ALTERA_SENHA_FAIL = 'USUARIO_ALTERA_SENHA_REQUEST';

export const USUARIO_REGISTER_REQUEST = 'USUARIO_REGISTER_REQUEST';
export const USUARIO_REGISTER_SUCCESS = 'USUARIO_REGISTER_SUCCESS';
export const USUARIO_REGISTER_FAIL = 'USUARIO_REGISTER_FAIL';

export const USUARIO_COMPLETA_REQUEST = 'USUARIO_COMPLETA_REQUEST';
export const USUARIO_COMPLETA_SUCCESS = 'USUARIO_COMPLETA_SUCCESS';
export const USUARIO_COMPLETA_FAIL = 'USUARIO_COMPLETA_FAIL';

export const USUARIO_RELOAD_REQUEST = 'USUARIO_RELOAD_REQUEST';
export const USUARIO_RELOAD_SUCCESS = 'USUARIO_RELOAD_SUCCESS';
export const USUARIO_RELOAD_FAIL = 'USUARIO_RELOAD_FAIL';

export const USUARIO_VINCULADO_AO_COMPLETAR_CADASTRO =
  'USUARIO_VINCULADO_AO_COMPLETAR_CADASTRO';

export const USUARIO_ACEITE_TERMOS = 'USUARIO_ACEITE_TERMOS';
